import firebase from "firebase";

export var vt2022httpapi = function (baseUri) {
  const headers = (token) => {
    return {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    };
  };

  const authWaitAsync = async () => {
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged((user) => {
        resolve();
      });
    });
  };

  const authHeaders = async (token) => {
    await authWaitAsync();
    return headers(
      await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
    );
  };

  const authHeadersCallback = (callback) => {
    firebase.auth().onAuthStateChanged((user) => {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (token) {
          callback(token);
        });
    });
  };

  const createUri = (path) => baseUri + path;

  const handleResponseError = (response) => {
    if (response.ok) {
      return;
    }
    throw Error(response.status);
  };

  return {
    source: baseUri,

    createAPIUser: ({
      data,
      didCreateUserCallback,
      didUpdateUserCallback,
      errorCallback,
    }) => {
      authHeadersCallback((token) => {
        try {
          fetch(createUri("/users/create"), {
            method: "POST",
            headers: headers(token),
            body: JSON.stringify(data),
          })
            .then((response) => {
              if (response.ok) {
                didCreateUserCallback(response);
                didUpdateUserCallback(response);
              } else {
                errorCallback(response.status);
              }
            })
            .catch((error) => {
              errorCallback(error);
            });
        } catch (error) {
          errorCallback(error);
        }
      });
    },

    getAPIUserId: async ({ firebaseUserId }) => {
      var response = await fetch(createUri("/users/show"), {
        method: "GET",
        headers: await authHeaders(),
      });
      if (!response.ok) {
        return null;
      }
      return (await response.json())["id"];
    },

    incLoginCount: ({ callback }) => {
      authHeadersCallback((token) => {
        try {
          fetch(createUri("/users/inc_login_count"), {
            method: "POST",
            headers: headers(token),
          })
            .then((response) => {
              callback(response);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (error) {
          console.log(error);
        }
      });
    },

    existSurvey: async ({ apiUserId }) => {
      var response = await fetch(createUri("/users/survey"), {
        method: "GET",
        headers: await authHeaders(),
      });
      handleResponseError(response);
      return (await response.json()) != null;
    },

    updateSurvey: async ({ apiUserId, data }) => {
      var response = await fetch(createUri("/users/survey"), {
        method: "POST",
        headers: await authHeaders(),
        body: JSON.stringify(data),
      });
      handleResponseError(response);
      return response.ok;
    },

    incBusinessCardCountBooth: async ({ companyId }) => {
      var data = { company_firebase_user_id: companyId };
      var response = await fetch(
        createUri("/users/business_card/inc_booth_count"),
        {
          method: "POST",
          headers: await authHeaders(),
          body: JSON.stringify(data),
        }
      );
      handleResponseError(response);
      return response.ok;
    },

    incBusinessCardCountSeminar: async ({ seminarCompanyId }) => {
      var data = { company_firebase_user_id: seminarCompanyId };
      var response = await fetch(
        createUri("/users/business_card/inc_seminar_count"),
        {
          method: "POST",
          headers: await authHeaders(),
          body: JSON.stringify(data),
        }
      );
      handleResponseError(response);
      return await response.json();
    },

    updateSeminarSurvey: async ({ seminarCompanyId, q1, q2, q3, q4 }) => {
      var data = {
        company_firebase_user_id: seminarCompanyId,
        survey_q1: q1,
        survey_q2: q2,
        survey_q3: q3,
        survey_q4: q4,
      };
      var response = await fetch(createUri("users/business_card/survey"), {
        method: "POST",
        headers: await authHeaders(),
        body: JSON.stringify(data),
      });
      handleResponseError(response);
      return response.ok;
    },

    getCompany: async ({ companyId }) => {
      var response = await fetch(
        createUri("/users/company?firebase_user_id=" + companyId),
        { headers: await authHeaders() }
      );
      handleResponseError(response);
      return await response.json();
    },

    getCompanies: async () => {
      var response = await fetch(createUri("/users/companies"), {
        headers: await authHeaders(),
      });
      handleResponseError(response);
      return await response.json();
    },
  };
};
