const BOOTH_TO_COMPANY_ID_STG = {
  "jfrZ5F74KfbmPvy4w9ByNp23EVj2":"jfrZ5F74KfbmPvy4w9ByNp23EVj2",
  "HXMJkN8A7pSowjyJnJlV2PKfbx83":"HXMJkN8A7pSowjyJnJlV2PKfbx83",
  "cubQVJnaSna6jC9QXUw62tVY0Ov1":"cubQVJnaSna6jC9QXUw62tVY0Ov1",
  "bPhMAQkXxaSWtdYJXHQX0flFodO2":"bPhMAQkXxaSWtdYJXHQX0flFodO2",
  "qqYJSvtmHNeTKXu3zS0rWWIdth33":"qqYJSvtmHNeTKXu3zS0rWWIdth33",
  "xT0jXElvWgSFihdVSbXKiWfUuz63":"xT0jXElvWgSFihdVSbXKiWfUuz63",
  "KKbjaDE8iEMdW3JCzbVlnUvrPWm1":"KKbjaDE8iEMdW3JCzbVlnUvrPWm1",
  "0GTVtgxNpWVEBCKhBCj2Ffg3G1C2":"0GTVtgxNpWVEBCKhBCj2Ffg3G1C2",
  "fRfGZF1cL4Znp83aGxuV921OlJX2":"fRfGZF1cL4Znp83aGxuV921OlJX2",
  "O9SA1VdlEAcLE7S9o1tcXbqk8XB3":"O9SA1VdlEAcLE7S9o1tcXbqk8XB3",
  "CxZ2W5GhBPOuccL3RrccHRdUyjm2":"CxZ2W5GhBPOuccL3RrccHRdUyjm2",
  "eM2QPKzV4aUquNKEVGG0x5N7d0P2":"eM2QPKzV4aUquNKEVGG0x5N7d0P2",
  "kURBf4eYD1TQCgmRA8rFT98OP7o2":"kURBf4eYD1TQCgmRA8rFT98OP7o2",
  "zHBZmUbRz2WNOzhiJL9yEfOY4v03":"zHBZmUbRz2WNOzhiJL9yEfOY4v03",
  "yyxY5ZKLE8PbWh8FxvoyIZYOEPE2":"yyxY5ZKLE8PbWh8FxvoyIZYOEPE2",
  "u72zyNYUxrZSRw97yV55aIxMrrp2":"u72zyNYUxrZSRw97yV55aIxMrrp2",
  "bzz1Nh9LjwW9rTabg9LS7ISJxsO2":"bzz1Nh9LjwW9rTabg9LS7ISJxsO2",
  "FHNumqPiiqaxJ39DnJ14BISNCLZ2":"FHNumqPiiqaxJ39DnJ14BISNCLZ2",
  "2jGesYOJXmemlkGmKc623G496ZB3":"2jGesYOJXmemlkGmKc623G496ZB3",
  "T6UGLI6yFtMcJ1T83tDp8LNeaX43":"T6UGLI6yFtMcJ1T83tDp8LNeaX43",
  "3jUb4ByOV8b2DLn14iLjRacTUGC3":"3jUb4ByOV8b2DLn14iLjRacTUGC3",
  "MRmbMPcLxqNeVSvKHe1I9YKTME33":"MRmbMPcLxqNeVSvKHe1I9YKTME33",
  "F6ojRDdtiXW9S9DpKd6dSyIh6Bz1":"F6ojRDdtiXW9S9DpKd6dSyIh6Bz1",
  "aRdzKuF3bpYAb6FLIRL9qDEgevt1":"aRdzKuF3bpYAb6FLIRL9qDEgevt1",
  "8nK4yweYcEgVT66GLFqHPkzMvUv1":"8nK4yweYcEgVT66GLFqHPkzMvUv1",
  "p5JfJw3a8KO6dmVj8yqA1c3JGpD3":"p5JfJw3a8KO6dmVj8yqA1c3JGpD3",
  "4JWTnzNP33ZeWgThBXmBWHpdEdc2":"4JWTnzNP33ZeWgThBXmBWHpdEdc2",
  "5YLRf5xnBBO1xDpuZc4gHcFoGOf1":"5YLRf5xnBBO1xDpuZc4gHcFoGOf1",
  "y9pKk9JUMRXIsKYusJ7oFgr6MR13":"y9pKk9JUMRXIsKYusJ7oFgr6MR13",
  "grJrhPE9flOg4ee5UegGoTS7RyH2":"grJrhPE9flOg4ee5UegGoTS7RyH2",
  "ubxHeym2FaSqO00zwN0t46Edr2y2":"ubxHeym2FaSqO00zwN0t46Edr2y2",
  "alvAD5VD0NfrCWAG9r83Lt8abzt2":"alvAD5VD0NfrCWAG9r83Lt8abzt2",
  "yryTXBcpmQgNAp4YtFiTlcUZH473":"yryTXBcpmQgNAp4YtFiTlcUZH473",
  "etdiMIxxZ7etSfhWEC6m1KVODJf2":"etdiMIxxZ7etSfhWEC6m1KVODJf2",
  "kkxsCUIdaATezZsRX6js0RnE6Uf1":"kkxsCUIdaATezZsRX6js0RnE6Uf1",
  "pKb5Ve0bkZYsARzVzvjKwvHDkEv1":"pKb5Ve0bkZYsARzVzvjKwvHDkEv1",
  "tJBH03BOy7Ugkr8R5DFUbNWxnPE3":"tJBH03BOy7Ugkr8R5DFUbNWxnPE3",
  "5aEJxKq3EVhVEWP5fWlgymjEH583":"5aEJxKq3EVhVEWP5fWlgymjEH583",
  "KcnjLeOTzRZ2t1Bg608q29NV28k1":"KcnjLeOTzRZ2t1Bg608q29NV28k1",
  "WlJJNwmJQ6efiyvtUgdtBvOYADE3":"WlJJNwmJQ6efiyvtUgdtBvOYADE3",
  "o2fi5Rhs69cNKfwPqifx83X7iHz1":"o2fi5Rhs69cNKfwPqifx83X7iHz1",
  "VUHkQPlCjldePT8Vzb7kis4EaFC2":"VUHkQPlCjldePT8Vzb7kis4EaFC2",
  "cWCgvosKBndgrwXfdHeIcizmd2f1":"cWCgvosKBndgrwXfdHeIcizmd2f1",
  "jfIzyWjckJYTYIeAon16u0rY0EJ3":"jfIzyWjckJYTYIeAon16u0rY0EJ3",
  "lXFyGvQo39cRgXyM8M896nFC0402":"lXFyGvQo39cRgXyM8M896nFC0402",
  "4r0TEPLitLOuqgBs98weK6QPavA2":"4r0TEPLitLOuqgBs98weK6QPavA2",
  "lOY6gTKisVTEb3tCCM11BDAdAfx1":"lOY6gTKisVTEb3tCCM11BDAdAfx1",
  "4rkaz305yhTDpHjAIMLQgFBAgD12":"4rkaz305yhTDpHjAIMLQgFBAgD12",
  "vioB7XSDS9P5WFAp3cn6Vnoft2v1":"vioB7XSDS9P5WFAp3cn6Vnoft2v1",
  "xjrKJFDi0QbdFvFPXWDlpzIiObC2":"xjrKJFDi0QbdFvFPXWDlpzIiObC2",
  "3hwvqLFWHXUMt7AoAShc5agz5qN2":"3hwvqLFWHXUMt7AoAShc5agz5qN2",
  "Yp9A1AdaIoNl6ZLDf1ogwYdlBK13":"Yp9A1AdaIoNl6ZLDf1ogwYdlBK13",
  "Xzy99fpRUjhOSITZ8I6wgHERO6a2":"Xzy99fpRUjhOSITZ8I6wgHERO6a2",
  "bpqwWt85TvSG0RpiXQfxYzcJDkC2":"bpqwWt85TvSG0RpiXQfxYzcJDkC2",
  "nb5NdlAjyQPb9Vh1W9sf3JI9pNS2":"nb5NdlAjyQPb9Vh1W9sf3JI9pNS2",
  "YnUbs7Jk2WWwp6hZ3Qac3nirLi03":"YnUbs7Jk2WWwp6hZ3Qac3nirLi03",
  "dU1vwANRzxZVkHNXx2hAUb8xzz72":"dU1vwANRzxZVkHNXx2hAUb8xzz72",
  "5izahPPTpthTOwuhzyrj2nGT52q1":"5izahPPTpthTOwuhzyrj2nGT52q1",
  "NrHehUvfaFTs1OUb91xiIWX4xWC2":"NrHehUvfaFTs1OUb91xiIWX4xWC2",
  "J3CCToI1f0cQcbA57LPfgKzATIs2":"J3CCToI1f0cQcbA57LPfgKzATIs2",
  "TUR7mT6mV2h2hir9HKEgtD9HQxF2":"TUR7mT6mV2h2hir9HKEgtD9HQxF2",
  "kN4KeaPF7jYxe3p7NoUUvhz0bO82":"kN4KeaPF7jYxe3p7NoUUvhz0bO82",
  "bY3cbACg3la7dgp0KHXnuuytH052":"bY3cbACg3la7dgp0KHXnuuytH052",
  "2xuXZJpesGXvrxl7GI1EEG1bvEg1":"2xuXZJpesGXvrxl7GI1EEG1bvEg1",
  "a4TOrOddK4XRfnmSPSXu4384FK33":"a4TOrOddK4XRfnmSPSXu4384FK33",
  "r0Wa6vgKEPM3Fd811o5c9ojXWhU2":"r0Wa6vgKEPM3Fd811o5c9ojXWhU2",
  "y3wJ7Y82TgelZdsWh53vFKzhBk82":"y3wJ7Y82TgelZdsWh53vFKzhBk82",
  "KBVQElCvUmOT3CS8j8jY6zbRTF32":"KBVQElCvUmOT3CS8j8jY6zbRTF32",
  "YvbbowXvpPO1uq2wAY05cKHxLPF3":"YvbbowXvpPO1uq2wAY05cKHxLPF3",
  "wCjlt26gsOTYL0FNLtvOb1iRhUo1":"wCjlt26gsOTYL0FNLtvOb1iRhUo1",
};

const BOOTH_TO_COMPANY_ID_PROD = {
  "jfrZ5F74KfbmPvy4w9ByNp23EVj2":"jfrZ5F74KfbmPvy4w9ByNp23EVj2",
  "HXMJkN8A7pSowjyJnJlV2PKfbx83":"HXMJkN8A7pSowjyJnJlV2PKfbx83",
  "cubQVJnaSna6jC9QXUw62tVY0Ov1":"cubQVJnaSna6jC9QXUw62tVY0Ov1",
  "bPhMAQkXxaSWtdYJXHQX0flFodO2":"bPhMAQkXxaSWtdYJXHQX0flFodO2",
  "qqYJSvtmHNeTKXu3zS0rWWIdth33":"qqYJSvtmHNeTKXu3zS0rWWIdth33",
  "xT0jXElvWgSFihdVSbXKiWfUuz63":"xT0jXElvWgSFihdVSbXKiWfUuz63",
  "KKbjaDE8iEMdW3JCzbVlnUvrPWm1":"KKbjaDE8iEMdW3JCzbVlnUvrPWm1",
  "0GTVtgxNpWVEBCKhBCj2Ffg3G1C2":"0GTVtgxNpWVEBCKhBCj2Ffg3G1C2",
  "fRfGZF1cL4Znp83aGxuV921OlJX2":"fRfGZF1cL4Znp83aGxuV921OlJX2",
  "O9SA1VdlEAcLE7S9o1tcXbqk8XB3":"O9SA1VdlEAcLE7S9o1tcXbqk8XB3",
  "CxZ2W5GhBPOuccL3RrccHRdUyjm2":"CxZ2W5GhBPOuccL3RrccHRdUyjm2",
  "eM2QPKzV4aUquNKEVGG0x5N7d0P2":"eM2QPKzV4aUquNKEVGG0x5N7d0P2",
  "kURBf4eYD1TQCgmRA8rFT98OP7o2":"kURBf4eYD1TQCgmRA8rFT98OP7o2",
  "zHBZmUbRz2WNOzhiJL9yEfOY4v03":"zHBZmUbRz2WNOzhiJL9yEfOY4v03",
  "yyxY5ZKLE8PbWh8FxvoyIZYOEPE2":"yyxY5ZKLE8PbWh8FxvoyIZYOEPE2",
  "u72zyNYUxrZSRw97yV55aIxMrrp2":"u72zyNYUxrZSRw97yV55aIxMrrp2",
  "bzz1Nh9LjwW9rTabg9LS7ISJxsO2":"bzz1Nh9LjwW9rTabg9LS7ISJxsO2",
  "FHNumqPiiqaxJ39DnJ14BISNCLZ2":"FHNumqPiiqaxJ39DnJ14BISNCLZ2",
  "2jGesYOJXmemlkGmKc623G496ZB3":"2jGesYOJXmemlkGmKc623G496ZB3",
  "T6UGLI6yFtMcJ1T83tDp8LNeaX43":"T6UGLI6yFtMcJ1T83tDp8LNeaX43",
  "3jUb4ByOV8b2DLn14iLjRacTUGC3":"3jUb4ByOV8b2DLn14iLjRacTUGC3",
  "MRmbMPcLxqNeVSvKHe1I9YKTME33":"MRmbMPcLxqNeVSvKHe1I9YKTME33",
  "F6ojRDdtiXW9S9DpKd6dSyIh6Bz1":"F6ojRDdtiXW9S9DpKd6dSyIh6Bz1",
  "aRdzKuF3bpYAb6FLIRL9qDEgevt1":"aRdzKuF3bpYAb6FLIRL9qDEgevt1",
  "8nK4yweYcEgVT66GLFqHPkzMvUv1":"8nK4yweYcEgVT66GLFqHPkzMvUv1",
  "p5JfJw3a8KO6dmVj8yqA1c3JGpD3":"p5JfJw3a8KO6dmVj8yqA1c3JGpD3",
  "4JWTnzNP33ZeWgThBXmBWHpdEdc2":"4JWTnzNP33ZeWgThBXmBWHpdEdc2",
  "5YLRf5xnBBO1xDpuZc4gHcFoGOf1":"5YLRf5xnBBO1xDpuZc4gHcFoGOf1",
  "y9pKk9JUMRXIsKYusJ7oFgr6MR13":"y9pKk9JUMRXIsKYusJ7oFgr6MR13",
  "grJrhPE9flOg4ee5UegGoTS7RyH2":"grJrhPE9flOg4ee5UegGoTS7RyH2",
  "ubxHeym2FaSqO00zwN0t46Edr2y2":"ubxHeym2FaSqO00zwN0t46Edr2y2",
  "alvAD5VD0NfrCWAG9r83Lt8abzt2":"alvAD5VD0NfrCWAG9r83Lt8abzt2",
  "yryTXBcpmQgNAp4YtFiTlcUZH473":"yryTXBcpmQgNAp4YtFiTlcUZH473",
  "etdiMIxxZ7etSfhWEC6m1KVODJf2":"etdiMIxxZ7etSfhWEC6m1KVODJf2",
  "kkxsCUIdaATezZsRX6js0RnE6Uf1":"kkxsCUIdaATezZsRX6js0RnE6Uf1",
  "pKb5Ve0bkZYsARzVzvjKwvHDkEv1":"pKb5Ve0bkZYsARzVzvjKwvHDkEv1",
  "tJBH03BOy7Ugkr8R5DFUbNWxnPE3":"tJBH03BOy7Ugkr8R5DFUbNWxnPE3",
  "5aEJxKq3EVhVEWP5fWlgymjEH583":"5aEJxKq3EVhVEWP5fWlgymjEH583",
  "KcnjLeOTzRZ2t1Bg608q29NV28k1":"KcnjLeOTzRZ2t1Bg608q29NV28k1",
  "WlJJNwmJQ6efiyvtUgdtBvOYADE3":"WlJJNwmJQ6efiyvtUgdtBvOYADE3",
  "o2fi5Rhs69cNKfwPqifx83X7iHz1":"o2fi5Rhs69cNKfwPqifx83X7iHz1",
  "VUHkQPlCjldePT8Vzb7kis4EaFC2":"VUHkQPlCjldePT8Vzb7kis4EaFC2",
  "cWCgvosKBndgrwXfdHeIcizmd2f1":"cWCgvosKBndgrwXfdHeIcizmd2f1",
  "jfIzyWjckJYTYIeAon16u0rY0EJ3":"jfIzyWjckJYTYIeAon16u0rY0EJ3",
  "lXFyGvQo39cRgXyM8M896nFC0402":"lXFyGvQo39cRgXyM8M896nFC0402",
  "4r0TEPLitLOuqgBs98weK6QPavA2":"4r0TEPLitLOuqgBs98weK6QPavA2",
  "lOY6gTKisVTEb3tCCM11BDAdAfx1":"lOY6gTKisVTEb3tCCM11BDAdAfx1",
  "4rkaz305yhTDpHjAIMLQgFBAgD12":"4rkaz305yhTDpHjAIMLQgFBAgD12",
  "vioB7XSDS9P5WFAp3cn6Vnoft2v1":"vioB7XSDS9P5WFAp3cn6Vnoft2v1",
  "xjrKJFDi0QbdFvFPXWDlpzIiObC2":"xjrKJFDi0QbdFvFPXWDlpzIiObC2",
  "3hwvqLFWHXUMt7AoAShc5agz5qN2":"3hwvqLFWHXUMt7AoAShc5agz5qN2",
  "Yp9A1AdaIoNl6ZLDf1ogwYdlBK13":"Yp9A1AdaIoNl6ZLDf1ogwYdlBK13",
  "Xzy99fpRUjhOSITZ8I6wgHERO6a2":"Xzy99fpRUjhOSITZ8I6wgHERO6a2",
  "bpqwWt85TvSG0RpiXQfxYzcJDkC2":"bpqwWt85TvSG0RpiXQfxYzcJDkC2",
  "nb5NdlAjyQPb9Vh1W9sf3JI9pNS2":"nb5NdlAjyQPb9Vh1W9sf3JI9pNS2",
  "YnUbs7Jk2WWwp6hZ3Qac3nirLi03":"YnUbs7Jk2WWwp6hZ3Qac3nirLi03",
  "dU1vwANRzxZVkHNXx2hAUb8xzz72":"dU1vwANRzxZVkHNXx2hAUb8xzz72",
  "5izahPPTpthTOwuhzyrj2nGT52q1":"5izahPPTpthTOwuhzyrj2nGT52q1",
  "NrHehUvfaFTs1OUb91xiIWX4xWC2":"NrHehUvfaFTs1OUb91xiIWX4xWC2",
  "J3CCToI1f0cQcbA57LPfgKzATIs2":"J3CCToI1f0cQcbA57LPfgKzATIs2",
  "TUR7mT6mV2h2hir9HKEgtD9HQxF2":"TUR7mT6mV2h2hir9HKEgtD9HQxF2",
  "kN4KeaPF7jYxe3p7NoUUvhz0bO82":"kN4KeaPF7jYxe3p7NoUUvhz0bO82",
  "bY3cbACg3la7dgp0KHXnuuytH052":"bY3cbACg3la7dgp0KHXnuuytH052",
  "2xuXZJpesGXvrxl7GI1EEG1bvEg1":"2xuXZJpesGXvrxl7GI1EEG1bvEg1",
  "a4TOrOddK4XRfnmSPSXu4384FK33":"a4TOrOddK4XRfnmSPSXu4384FK33",
  "r0Wa6vgKEPM3Fd811o5c9ojXWhU2":"r0Wa6vgKEPM3Fd811o5c9ojXWhU2",
  "y3wJ7Y82TgelZdsWh53vFKzhBk82":"y3wJ7Y82TgelZdsWh53vFKzhBk82",
  "KBVQElCvUmOT3CS8j8jY6zbRTF32":"KBVQElCvUmOT3CS8j8jY6zbRTF32",
  "YvbbowXvpPO1uq2wAY05cKHxLPF3":"YvbbowXvpPO1uq2wAY05cKHxLPF3",
  "wCjlt26gsOTYL0FNLtvOb1iRhUo1":"wCjlt26gsOTYL0FNLtvOb1iRhUo1",
};

// "入稿サイトの企業ユーザーFirebase authentication UID": "展示会サイトの企業ユーザーFirebase authentication UID"
export const BOOTH_TO_COMPANY_ID = /production/.test(
  process.env.REACT_APP_REAL_TIME_DATABASE_PATH_PREFIX
)
  ? BOOTH_TO_COMPANY_ID_PROD
  : BOOTH_TO_COMPANY_ID_STG;

export const STORE_KEY_BOOTH = "store-key-booth-id";
export const STORE_KEY_REDIRECT_PATH = "store-key-redirect-path";

const SEMINARS_STG = [
  "iWyJSnBWTHaK6QTcA0HwlEUgwu32",
  "8FNWMttdnwSzO6FwLSaYC4j0u4N2",
  "pBD6sSG7QigFOlTolXyxHps7LIr2",
  "TVO5G892Y1Z5415mtUeFTamK1452",
];

const SEMINARS_PROD = [
  "KDCjoANoIKhKhHwNB7unxtPDGqp1",
  "E2tGAoIoVPh4zU4ffHUoxSZGaQ03",
  "mSQ4RTO555c7c3yzRKeXNGAG8KI3",
  "xwnLFYlmamTumPPujisVHpR151m2",
];

export const SEMINARS = /production/.test(
  process.env.REACT_APP_REAL_TIME_DATABASE_PATH_PREFIX
)
  ? SEMINARS_PROD
  : SEMINARS_STG;

export const SEMINAR_CONTENTS = [
  {
    id: 1,
    title:
      "工場セキュリティ対策を推進するためのリアルな戦略～顧客対応から見えた課題とその打ち手、未来の工場セキュリティ～",
    speakers: [
      {
        id: 1,
        photo: "https://jpn-expo.com/img/lecture/img-1-1.jpg",
        company: "トレンドマイクロ株式会社",
        role: "代表取締役副社長",
        name: "大三川彰彦 氏",
      },
      {
        id: 2,
        photo: "https://jpn-expo.com/img/lecture/img-1-2.jpg",
        company: "トレンドマイクロ株式会社",
        role: "セキュリティエキスパート本部 ソリューション推進部ソリューションイネーブルメントチーム プリンシパルソリューションコンサルタント",
        name: "喜多裕良 氏",
      },
    ],
    video: { id: "681208535", url: "https://player.vimeo.com/video/681208535" },
  },
  {
    id: 2,
    title:
      "如何にして場の状況に適応した行動を可能とするか？〜自律型AIのためのプランニング〜",
    speakers: [
      {
        id: 3,
        photo: "https://jpn-expo.com/img/lecture/img-2-1.jpg",
        company: "慶應義塾大学理工学部教授",
        role: "電気通信大学人工知能先端研究センター特任教授",
        name: "栗原 聡 教授",
      },
    ],
    video: { id: "681208430", url: "https://player.vimeo.com/video/681208430" },
  },
  {
    id: 3,
    title: "シミュレーションによるノイズ対策とパワーエレクトロニクス設計",
    speakers: [
      {
        id: 4,
        photo: "https://jpn-expo.com/img/lecture/img-3-1.jpg",
        company: "サイバネットシステム株式会社",
        role: "CAE第1事業部エレクトロニクス技術部",
        name: "柳 明男 氏",
      },
      {
        id: 5,
        photo: "https://jpn-expo.com/img/lecture/img-3-2.jpg",
        company: "サイバネットシステム株式会社",
        role: "CAE第1事業部エレクトロニクス技術部",
        name: "渡邉 侑子 氏",
      },
    ],
    video: { id: "681208231", url: "https://player.vimeo.com/video/681208231" },
  },
  {
    id: 4,
    title: "製造✕DX ミスミが仕掛ける製造業の未来",
    speakers: [
      {
        id: 6,
        photo: "https://jpn-expo.com/img/lecture/img-4-1.jpg",
        company: "ミスミグループ本社",
        role: "常務執行役員 ID企業体社長",
        name: "吉田光伸 氏",
      },
    ],
    video: { id: "681208777", url: "https://player.vimeo.com/video/681208777" },
  },
];
