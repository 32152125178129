import React, { useCallback, useState } from "react";
import { withRouter } from "react-router";
import { isMobile } from "react-device-detect";
import app from "./base";
import firebase from "firebase";
import { vt2022api } from "./api/index.js";

const SignUp = ({ history }) => {
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  const [error6, setError6] = useState(false);
  const [error7, setError7] = useState(false);
  const [error8, setError8] = useState(false);

  const handleSignUp = useCallback(
    async (event) => {
      event.preventDefault();
      const {
        familyName,
        givenName,
        comName,
        jobName,
        fieldName,
        address,
        phone,
        email,
        password,
      } = event.target.elements;
      let isValid = true;
      if (familyName.value === "" || givenName.value === "") {
        setError1(true);
        isValid = false;
      }
      if (comName.value === "") {
        setError2(true);
        isValid = false;
      }
      if (
        phone.value === "" ||
        phone.value.length < 10 ||
        phone.value.length > 12
      ) {
        setError5(true);
        isValid = false;
      }
      if (address.value === "") {
        setError6(true);
        isValid = false;
      }
      if (email.value === "" || !email.value.includes("@")) {
        setError7(true);
        isValid = false;
      }
      if (password.value === "" || password.value.length < 6) {
        setError8(true);
        isValid = false;
      }
      if (isValid === true) {
        var errorCode;
        try {
          const name = `${familyName.value} ${givenName.value}`;
          await app
            .auth()
            .createUserWithEmailAndPassword(email.value, password.value)
            .then(function () {
              firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                  console.log("userID", app.auth().currentUser.uid);
                  const userId = app.auth().currentUser.uid;
                  const data = {
                    address: address.value,
                    companyName: comName.value,
                    email: email.value,
                    fieldName: fieldName.value,
                    id: userId,
                    jobName: jobName.value,
                    nickname: name,
                    passwordExpiredAt: "",
                    passwordReset: "",
                    phoneNum: phone.value,
                    resetCode: "",
                    isMobile: isMobile ? 1 : 0,
                  };

                  vt2022api().createAPIUser({
                    data: data,
                    didCreateUserCallback: function () {
                      localStorage.setItem("saveData", JSON.stringify(data));
                      localStorage.setItem("userID", userId);
                    },
                    didUpdateUserCallback: function () {
                      window.location.href = "/";
                    },
                    errorCallback: function (e) {
                      console.log("create user error: " + e);
                    },
                  });
                } else {
                  //no user
                }
              });
            })
            .catch(function (error) {
              errorCode = error.code;

              switch (errorCode) {
                case "auth/email-already-in-use": {
                  alert(
                    "メールアドレスはすでに別のアカウントで使用されています。"
                  );
                  break;
                }
                default:
                  break;
              }
              console.log("auth error", error);
            });
          //history.push("/");
        } catch (error) {
          alert(error);
        }
      }
    },
    [history]
  );

  return (
    <div>
      <div className="form-border">
        <div className="form-border-inn">
          <strong style={{}}></strong>
          <a
            href="/login"
            style={{
              textUnderlineOffset: "1.5px",
              alignSelf: "center",
              marginLeft: "auto",
              float: "right",
              display: "block",
              color: "#22ac38",
              fontSize: "14px",
            }}
          >
            ログイン
          </a>
        </div>
      </div>
      <div className="usccart_navi">
        <ol className="ucart">
          <li className="ucart usccart_customer">ユーザー登録</li>
          <li className="ucart uscdelivery">ユーザー登録完了</li>
        </ol>
      </div>
      <div className="form-base">
        <div className="form-body">
          <h1>ユーザー登録</h1>
          <p
            style={{
              fontSize: "14px",
              lineHeight: "1.8em",
              margin: "0 0 25px",
              textAlign: "center",
            }}
          >
            ユーザー登録完了後すぐに展示会場にご入場いただけます。既に登録がお済みの方は
            <a href="/login">こちら</a>からログインしてください。
          </p>

          <h2>お客様情報</h2>
          <form onSubmit={handleSignUp}>
            <table>
              <tbody>
                <tr>
                  <th>お名前</th>
                  <td className="name-form">
                    <div className="form-text-group">
                      <input
                        className="form-text"
                        name="familyName"
                        type="text"
                        placeholder="姓"
                        required={true}
                        aria-required={true}
                        aria-invalid={true}
                      />
                      <input
                        className="form-text"
                        name="givenName"
                        type="text"
                        placeholder="名"
                        required={true}
                        aria-required={true}
                        aria-invalid={true}
                      />
                    </div>
                    {error1 ? (
                      <p className="form-error">
                        正しく入力されているか確認してください。
                      </p>
                    ) : (
                      <p className="form-error"></p>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>住所</th>
                  <td className="add-form">
                    <input
                      className="form-text"
                      name="address"
                      type="text"
                      placeholder="東京都江東区青海2-5-10 テレコムセンタービル東棟14階"
                      required={true}
                      aria-required={true}
                      aria-invalid={true}
                    />
                    {error6 ? (
                      <p className="form-error">
                        正しく入力されているか確認してください。
                      </p>
                    ) : (
                      <p className="form-error"></p>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td className="tel-form">
                    <input
                      className="form-text"
                      name="phone"
                      type="number"
                      placeholder="例) 0338520326"
                      required={true}
                      aria-required={true}
                      aria-invalid={true}
                    />
                    {error5 ? (
                      <p className="form-error">電話番号を確認してください。</p>
                    ) : (
                      <p className="form-error"></p>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>会社名</th>
                  <td className="company-form">
                    <input
                      className="form-text"
                      name="comName"
                      type="text"
                      placeholder="※個人の方は「個人」とご記入ください。"
                      required={true}
                      aria-required={true}
                      aria-invalid={true}
                    />
                    {error2 ? (
                      <p className="form-error">
                        正しく入力されているか確認してください。
                      </p>
                    ) : (
                      <p className="form-error"></p>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>部署名（任意）</th>
                  <td className="busho-form">
                    <input
                      className="form-text"
                      name="jobName"
                      type="text"
                      placeholder="例) 営業部"
                      required={false}
                      aria-required={true}
                      aria-invalid={true}
                    />
                    {error3 ? (
                      <p className="form-error">
                        正しく入力されているか確認してください。
                      </p>
                    ) : (
                      <p className="form-error"></p>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>役職（任意）</th>
                  <td className="yaku-form">
                    <input
                      className="form-text"
                      name="fieldName"
                      type="text"
                      placeholder="例) 営業部長"
                      required={false}
                      aria-required={true}
                      aria-invalid={true}
                    />
                    {error4 ? (
                      <p className="form-error">
                        正しく入力されているか確認してください。
                      </p>
                    ) : (
                      <p className="form-error"></p>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>メールアドレス</th>
                  <td className="mail-form">
                    <input
                      className="form-text"
                      name="email"
                      type="email"
                      placeholder="メールアドレス"
                      required={true}
                      aria-required={true}
                      aria-invalid={true}
                    />
                    {error7 ? (
                      <p className="form-error">
                        メールアドレスが正しくありません。
                      </p>
                    ) : (
                      <p className="form-error"></p>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>パスワード</th>
                  <td className="pw-form">
                    <input
                      className="form-text"
                      name="password"
                      type="password"
                      placeholder="6文字以上32文字以内でご記入ください。"
                      required={true}
                      aria-required={true}
                      aria-invalid={true}
                    />
                    {error8 ? (
                      <p className="form-error">
                        パスワードを半角英数字で6文字以上32文字以内で入力して下さい。
                      </p>
                    ) : (
                      <p className="form-error"></p>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <div style={{ marginBlockStart: "0.8rem" }}>
              <p
                style={{
                  fontSize: "11px",
                  lineHeight: "24px",
                  display: "block",
                  textAlign: "center",
                  color: "#555555",
                }}
              >
                上記の内容にお間違いがなければ、
                <a href="https://jpn-expo.com/suwamesse/privacypolicy.html" target="_blank">
                  プライバシーポリシー
                </a>
                に同意の上お進みください。
              </p>
            </div>
            <div className="form-button-base">
              <a href="https://suwamesse.jp/" className="form-backButton">
                前のページへ戻る
              </a>
              <button className="form-button" type="submit">
                同意して次に進む
              </button>
            </div>
          </form>
        </div>
      </div>
      <footer
        style={{
          background: "#000000",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "auto",
          width: "100%",
        }}
      >
        <p
          style={{
            color: "#ffffff",
            fontSize: "17px",
            textAlign: "center",
            padding: "10px 0",
            marginBlockEnd: "-1em",
          }}
        >
          <a href="https://jpn-expo.com/suwamesse/privacypolicy.html" target="_blank">
            プライバシーポリシー
          </a>
          <br />© 2002 – 2022 SUWA AREA INDUSTRIAL MESSE
        </p>
      </footer>
    </div>
  );
};

export default withRouter(SignUp);
