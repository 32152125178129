import React, { useContext, useEffect, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import { AuthContext } from "./Auth";
import { SEMINARS, SEMINAR_CONTENTS } from "./constants";
import { vt2022api } from "./api/index.js";

const api = vt2022api();

const Seminar = (props) => {
  const { currentUser } = useContext(AuthContext);
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [isSurveyVisible, setIsSurveyVisible] = useState(false);
  const [movingPage, setMovingPage] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const { params } = props.match;
  const id = parseInt(params.id, 10);
  const seminarCompanyId = SEMINARS[id - 1];
  const mainContent = SEMINAR_CONTENTS[id - 1];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { q1, q2, q3, q4 } = event.target.elements;

    if (seminarCompanyId) {
      try {
        await api.updateSeminarSurvey({
          seminarCompanyId: seminarCompanyId,
          q1: q1.value,
          q2: q2.value,
          q3: (q3.value + "").replace("/", "／"),
          q4: (q4.value + "").replace("/", "／"),
        });
        alert("ご協力ありがとうございました");
        setIsSurveyVisible(false);
        setCurrentSurvey(true);
        if (movingPage) {
          window.location.href = movingPage;
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleMovePage = (page) => {
    if (!currentSurvey) {
      setMovingPage(page);
      setIsSurveyVisible(true);
    } else {
      window.location.href = page;
    }
  };

  useEffect(() => {
    if (currentUser) {
      (async () => {
        const userId = currentUser.uid;
        if (!userId) {
          alert("タイムアウトしました。再度ログインしてください。");
          window.location.replace("/login");
        } else {
          const seminarCompanyId = SEMINARS[id - 1];
          const card = await api.incBusinessCardCountSeminar({
            seminarCompanyId: seminarCompanyId,
          });
          setCurrentSurvey(card.survey);
          setCompanyData(await api.getCompany({ companyId: seminarCompanyId }));
        }
      })();
    }
  }, [currentUser]);
  return (
    <div className="page-seminar">
      <div className="seminar-secondary">
        <div className="seminar-secondary-logo">
          <img
            src="https://jpn-expo.com/img/lecture/logo-white.png"
            alt="設計製造・AI・IoT・DX バーチャルオンライン展示会"
            width="100%"
            height="auto"
          />
        </div>
        <div className="seminar-list">
          <div className="seminar-list__title">講演一覧</div>
          <ul className="seminar-list__body">
            {SEMINAR_CONTENTS.map((content) => (
              <li className="seminar-list__item" key={`content-${content.id}`}>
                <a
                  href={void 0}
                  onClick={() => handleMovePage(`/seminar/${content.id}`)}
                >
                  <div className="seminar-list__item-title">
                    {content.title}
                  </div>
                  <div className="seminar-list__item-body">
                    {content.speakers.map((speaker) => (
                      <div
                        className="seminar-list__item-speaker"
                        key={`speaker-${speaker.id}`}
                      >
                        <div className="seminar-list__item-speaker-photo">
                          <img src={speaker.photo} />
                        </div>
                        <div className="seminar-list__item-speaker-name">
                          <div>{speaker.company}</div>
                          <div>{speaker.role}</div>
                          <div>{speaker.name}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <a
          href={void 0}
          onClick={() => handleMovePage("/")}
          className="seminar-btn"
        >
          展示会場に戻る
        </a>
      </div>
      <div className="seminar-main">
        <div className="seminar-video">
          <div className="seminar-video__container">
            <div className="seminar-video__title">{mainContent.title}</div>
            <Vimeo
              video={mainContent.video.id}
              className="seminar-video__video"
              responsive={true}
              onEnd={() => setIsSurveyVisible(true)}
            />
            <div className="seminar-video__buttons">
              {seminarCompanyId && (
                <a
                  href={void 0}
                  onClick={() => handleMovePage(`/?booth=${seminarCompanyId}`)}
                  className="seminar-btn"
                >
                  講演者のブースを見に行く
                </a>
              )}
              <a
                href={void 0}
                onClick={() => setIsSurveyVisible(true)}
                className="seminar-btn"
              >
                アンケートに答える
              </a>
            </div>
          </div>
        </div>
        <div className="seminar-video__info">
          <div className="seminar-video__info-title">講演者情報</div>
          <div className="seminar-video__info-body">
            {mainContent.speakers.map((speaker) => (
              <div
                className="seminar-video__info-speaker"
                key={`main-speaker-${speaker.id}`}
              >
                <div className="seminar-video__info-speaker-photo">
                  <img src={speaker.photo} />
                </div>
                <div className="seminar-video__info-speaker-name">
                  <div>{speaker.company}</div>
                  <div>
                    {speaker.role}　{speaker.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isSurveyVisible && (
        <div className="seminar-survey">
          <h2 style={{ fontSize: "1rem" }}>アンケートにご協力ください</h2>
          <form onSubmit={handleSubmit}>
            <h3 style={{ fontSize: "0.9rem" }}>内容はいかがでしたか？</h3>
            <div
              className="form-group-radio is-fullwidth"
              style={{ marginTop: "4px" }}
            >
              <label className="form-label">
                <input type="radio" name="q1" value="大変満足" />
                大変満足
              </label>
              <label className="form-label">
                <input type="radio" name="q1" value="満足" />
                満足
              </label>
              <label className="form-label">
                <input type="radio" name="q1" value="普通" />
                普通
              </label>
              <label className="form-label">
                <input
                  type="radio"
                  name="q1"
                  value="あまり参考にならなかった"
                />
                あまり参考にならなかった
              </label>
            </div>
            <h3 style={{ fontSize: "0.9rem", marginTop: "32px" }}>
              {companyData.enqueteQ2}
            </h3>
            <div
              className="form-group-radio is-fullwidth"
              style={{ marginTop: "4px" }}
            >
              <label className="form-label">
                <input type="radio" name="q2" value={companyData.enqueteQ2B1} />
                {companyData.enqueteQ2B1}
              </label>
              <label className="form-label">
                <input type="radio" name="q2" value={companyData.enqueteQ2B2} />
                {companyData.enqueteQ2B2}
              </label>
              <label className="form-label">
                <input type="radio" name="q2" value={companyData.enqueteQ2B3} />
                {companyData.enqueteQ2B3}
              </label>
              {companyData.enqueteQ2B4 ? (
                <label className="form-label">
                  <input
                    type="radio"
                    name="q2"
                    value={companyData.enqueteQ2B4}
                  />
                  {companyData.enqueteQ2B4}
                </label>
              ) : (
                ""
              )}
              {companyData.enqueteQ2B5 ? (
                <label className="form-label">
                  <input
                    type="radio"
                    name="q2"
                    value={companyData.enqueteQ2B5}
                  />
                  {companyData.enqueteQ2B5}
                </label>
              ) : (
                ""
              )}
            </div>
            <h3 style={{ fontSize: "0.9rem", marginTop: "32px" }}>
              {companyData.enqueteQ3}
            </h3>
            <textarea
              name="q3"
              style={{ marginTop: "8px", width: "100%", height: "3rem" }}
            />
            <h3 style={{ fontSize: "0.9rem", marginTop: "32px" }}>
              {companyData.enqueteQ4}
            </h3>
            <textarea
              name="q4"
              style={{ marginTop: "8px", width: "100%", height: "3rem" }}
            />
            <div style={{ marginTop: "8px", textAlign: "center" }}>
              <button type="submit">送信</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Seminar;
